<footer>
    <div class="footer-top pt-50 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-widget widget-one">
                        <div class="footer-widget-title">
                            <h3>ABOUT US</h3>
                        </div>
                        <div class="widget-about-content">
                            <p>Nivedita foundation is a charitable trust registered under The Indian Trust Act, 1882. The main aim of the trust is to follow Mission3D which stands for Dhee (daughters), Dharti (Mother earth), Dharohar (Heritage).</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12">
                    <div class="footer-widget widget-two">
                        <div class="footer-widget-title">
                            <h3>QUICK LINK</h3>
                        </div>
                        <nav>
                            <ul>
                                <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:;">Home</a></li>
                                <li><a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:;">About</a></li>
                                <li><a routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:;">Activities</a></li>
                                <li><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:;">Contact</a></li>
                                <li><a routerLink="/donation" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:;" class="bo-ra-3 button">Donation</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12">
                    <div class="footer-widget widget-three">
                        <div class="footer-widget-title">
                            <h3>SOCIAL LINKS</h3>
                        </div>
                        <nav class="foot_social_link">
                            <h3>Nivedita</h3>
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=100075915934898" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/niveditatrust/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                            <h3>Sapt Sindhu</h3>
                            <ul>
                                <li><a href="https://www.facebook.com/saptasindhungo/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://instagram.com/sapta__sindhu?utm_medium=copy_link" target="_blank"><i class="fa fa-instagram"></i> </a></li>
                                <li><a href="https://youtube.com/channel/UCumMoiLLtL5Mtu1wnGUPX2g" target="_blank"><i class="fa fa-youtube"></i></a></li>
                            </ul>
                            <h3>Aahar Kranti</h3>
                            <ul>

                                <li><a href="https://www.facebook.com/aaharkranti/" target="_blank"><i class="fa fa-facebook"></i> </a></li>
                                <li><a href="https://instagram.com/aaharkranti?utm_medium=copy_link" target="_blank"><i class="fa fa-instagram"></i> </a></li>
                                <li><a href="https://youtube.com/channel/UCahMYfcN8f1a4bKypI3OxEw" target="_blank"><i class="fa fa-youtube"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-widget widget-four">
                        <div class="footer-widget-title">
                            <h3>CONTACT US</h3>
                        </div>
                        <nav>
                            <ul>
                                <li><i class="fa fa-map"></i>House no 159, Phase 2, Mohali, Chandigarh
                                </li>
                                <li><a href="javascript:;"><i class="fa fa-envelope"></i>niveditatrust@gmail.com</a></li>
                                <li><i class="fa fa-phone"></i>9872885336, 8196833000, 9041396341</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>


        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="copyright">
                            <p>Copyrights © 2021.</p>
                        </div>
                    </div>
                </div>
                <div class="hidden-xs" id="back-top" style="display: block;"></div>
            </div>
        </div>

    </div>

</footer>