import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './_view/layout/auth-layout/auth-layout.component';
import { WebsiteLayoutComponent } from './_view/layout/website-layout/website-layout.component';


const routes: Routes = [

  {
    path: '',
    component: WebsiteLayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./_view/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'about', loadChildren: () => import('./_view/about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'activities', loadChildren: () => import('./_view/blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: 'contact', loadChildren: () => import('./_view/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'donation', loadChildren: () => import('./_view/donation/donation.module').then(m => m.DonationModule)
      },
      {
        path: "error",
        loadChildren: () =>
          import("./_view/error/error.module").then(m => m.ErrorModule)
      }
    ]
  },

  {
    path: "**",
    redirectTo: "error/404"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
