<div (window:scroll)="windowScroll()" class="cbp-af-header black-menu-background-1st-trans menu-fixed-padding-small menu-shadow " id="menu-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <nav class="navbar navbar-toggleable-md navbar-inverse bg-inverse bg-faded nav-line-top">
                    <button aria-controls="navbarNavMenuMain" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()" class="navbar-toggler navbar-toggler-right" data-target="#navbarNavMenuMain" data-toggle="collapse" type="button"><span
                            class="navbar-toggler-icon"></span></button>
                    <a class="navbar-brand" href="javascript:;">
                        <img routerLink="/" src="assets/img/logo.png" alt="logo">
                    </a>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarNavMenuMain">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" id="navbarDropdownMenuLink-lng"> Home </a>
                            </li>
                            <li class="nav-item dropdown" routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" id="navbarDropdownMenuLink-lng"> About </a>
                            </li>
                            <li class="nav-item dropdown" routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" id="navbarDropdownMenuLink-mainNav-33"> Activities </a>
                            </li>
                            <li class="nav-item dropdown" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" id="navbarDropdownMenuLink-lng"> Contact </a>
                            </li>
                            <li class="nav-item dropdown" routerLink="/donation" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a aria-expanded="false" aria-haspopup="true" class="bo-ra-3 button" data-toggle="dropdown" id="navbarDropdownMenuLink-lng"> Donate </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>